import { getMapMarkerColor } from '@js/map-marker-colors'

export const MAP_MARKER_TYPES = {
  HUB: 'hub',
  COURIER: 'courier',
  STANDARD: null,
  ASSIGNED: 'assigned-marker',
  SELECTED: 'selected-marker',
  MAIN: 'main-marker',
  SIDE: 'side-marker'
}

export function getIconSrc(type = MAP_MARKER_TYPES.STANDARD, startTime = null) {
  if (type === MAP_MARKER_TYPES.COURIER) {
    return '/images/circle-blue.png'
  }

  // NOTE: any color changes made here will have to be made in map-markers.scss
  let fillColor = 'white'
  switch (type) {
    case MAP_MARKER_TYPES.HUB:
      fillColor = '#F59292' // light-red
      break
    case MAP_MARKER_TYPES.SELECTED:
      fillColor = 'white'
      break
    case MAP_MARKER_TYPES.MAIN:
      fillColor = '#267FFF' // primary
      break
    case MAP_MARKER_TYPES.SIDE:
    case MAP_MARKER_TYPES.ASSIGNED:
      fillColor = '#999' // grey
      break
    default:
      fillColor = getMapMarkerColor(startTime)
  }

  return `/images/map-marker.png?color=${fillColor}`
}
