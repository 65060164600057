<template>
  <div class="timeslot-map-marker">
    <span class="timeslot-id">{{ id }}</span>
    <img
      :src="src"
      class="marker-icon-null">
  </div>
</template>

<script>
import { getIconSrc } from '@js/map-marker-utils'

export default {
  name: 'timeslot-map-marker',
  props: {
    id: {
      type: Number,
      required: true
    },
    startTime: {
      type: Number,
      required: true
    }
  },
  computed: {
    src() {
      return getIconSrc(null, this.startTime)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@css/map-markers';

.timeslot-map-marker {
  position: relative;
  line-height: 0;

  .timeslot-id {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 24px; // width of map-marker
    font-size: 12px;
    font-weight: bold;
    line-height: 25px; // height of map-marker - some px
    color: white;
    text-align: center;
  }
}
</style>
