import { INTERVAL, ONE_HOUR } from '@js/constants'

export const MAP_MARKER_COLORS = [
  // red
  '#FF6A6A',
  '#FF0000',
  '#BB0000',
  '#8F3C46',
  // yellow
  '#F5A623',
  '#EB7400',
  '#AD7636',
  '#793000',
  // green
  '#52C41A',
  '#039B49',
  '#237804',
  '#5F755E',
  // blue
  '#00B2EE',
  '#33A1C9',
  '#055078',
  '#022E40',
  // purple
  '#6482F9',
  '#0147FA',
  '#4981CE',
  '#2E5090',
  // magenta
  '#CD76FF',
  '#9B04F2',
  '#8052A9',
  '#2D0059',
  // pink
  '#DA70D6',
  '#EE00EE',
  '#C71585',
  '#A00289'
]

const EARLIEST_START_TIME = 8 * ONE_HOUR // 8:00AM

export function getMapMarkerColor(startTime) {
  const intervalIndex = (startTime - EARLIEST_START_TIME) / INTERVAL
  return MAP_MARKER_COLORS[intervalIndex % MAP_MARKER_COLORS.length]
}
